<template>
    <div>
        <router-view></router-view>
        <app-footer></app-footer>
    </div>
</template>

<script>
    import Navigation from './components/shared/Navigation.vue';
    import Footer from './components/shared/Footer.vue';

/*     import Masthead from './components/Masthead.vue';
    import Services from './components/Services.vue';
    import Portfolio from './components/Portfolio.vue';
    import About from './components/About.vue';
    import Team from './components/Team.vue';
    import Clients from './components/Clients.vue';
    import Contact from './components/Contact.vue';
 */
    export default {
        components: {
            appNavigation: Navigation,
            appFooter: Footer
        }
    }
</script>

<style>
</style>
