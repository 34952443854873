<template>
    <div style="margin-top:100px;" class="container">
        <app-navigation :show-menu="false" :force-shrink="true"></app-navigation>

        <div v-if="formCompleted">
            <h1>Registrierung erfolgreich</h1>
            <p>Vielen Dank für Ihre Registrierung, die Sie hiermit erfolgreich abgeschlossen haben.</p>
            <p>Was sind die nächsten Schritte?</p>
            <ol>
                <li>Ihre kryptische Vorsorge-E-Mail-Adresse lautet <span class="font-weight-bold">{{ cryptoMailAddress }}</span></li>
                <li>Sie hinterlegen die Adresse in den gewünschten Accounts und sind abgesichert.</li>
                <li>Sie ordnen Ihren Vertrauten die gesicherten Accounts zu.</li>
                <li v-if="userData.notarialCertification == '1'">Der Notar erhält die Registrierungsunterlagen und beurkundet diese.</li>
                <li><a :href="'https://password.habermann.consulting/assignment/' + this.secretKey">Nehmen Sie nun Ihre Zuordnungen vor!</a></li>
            </ol>
        </div>

        {{ info }}

        <form v-if="formCompleted == false">
        <h1>Registrierung</h1>
            <hr>
            <div class="row">
                <div class="col-xs-12 col-md-6">
                    <label for="firstname">Vorname</label>
                    <input
                            type="text"
                            id="firstname"
                            class="form-control"
                            :class="{ 'is-valid': validFirstname(userData.firstname), 'is-invalid': !validFirstname(userData.firstname) }"
                            :value="userData.firstname"
                            @input="userData.firstname = $event.target.value">
                    <div class="invalid-feedback">
                        Der Vorname muss mindestens 2 Zeichen lang sein.
                    </div>
                </div>
                <div v-if="validFirstname(userData.firstname)" class="col-xs-12 col-md-6">
                    <label for="lastname">Nachname</label>
                    <input
                            type="text"
                            id="lastname"
                            class="form-control"
                            :class="{ 'is-valid': validLastname(userData.lastname), 'is-invalid': !validLastname(userData.lastname) }"
                            :value="userData.lastname"
                            @input="userData.lastname = $event.target.value">
                    <div class="invalid-feedback">
                        Der Nachname muss mindestens 2 Zeichen lang sein.
                    </div>
                </div>
            </div>
            <!-- email -->
            <div class="row">
                <div v-if="validLastname(userData.lastname)" class="col-xs-12 col-md-6">
                    <label for="mail">Mail</label>
                    <input
                            type="text"
                            id="mail"
                            class="form-control"
                            :class="{ 'is-valid': validMail(userData.mail), 'is-invalid': !validMail(userData.mail) }"
                            :value="userData.mail"
                            @input="userData.mail = $event.target.value">
                    <div class="invalid-feedback">
                        Die E-Mail-Adresse muss das Format aaa@bbb.cc haben.
                    </div>
                </div>
            </div>
            <!-- Notarial Certification -->
            <div class="row" v-if="validMail(userData.mail)">
                <div class="col-xs-12 col-md-6">
                    <label for="notarialCertification">Notariell beurkunden</label>
                    <select
                            id="notarialCertification"
                            class="form-control"
                            :class="{ 'is-valid': validNotarialCertification(userData.notarialCertification), 'is-invalid': !validNotarialCertification(userData.notarialCertification) }"
                            v-model="userData.notarialCertification">
                        <option value="">Bitte auswählen ...</option>
                        <option value="1">Ja</option>
                        <option value="0">Nein</option>
                    </select>
                </div>
            </div>
            <!-- Address -->
            <div class="row" v-if="userData.notarialCertification == '1'">
                <div class="col-12">
                    <hr>
                </div>
            </div>
            <div class="row" v-if="userData.notarialCertification == '1'">
                    <div class="col-xs-12 col-md-6">
                        <label for="street">Straße</label>
                        <input
                                type="text"
                                id="street"
                                class="form-control"
                                :class="{ 'is-valid': validStreetname(userData.streetname), 'is-invalid': !validStreetname(userData.streetname) }"
                                :value="userData.streetname"
                                @input="userData.streetname = $event.target.value">
                        <div class="invalid-feedback">
                            Der Straßenname muss mindestens 2 Zeichen lang sein.
                        </div>
                    </div>
                    <div class="col-xs-12 col-md-2" v-if="validStreetname(userData.streetname)">
                        <label for="street">Hausnummer</label>
                        <input
                                type="text"
                                id="street"
                                class="form-control"
                                :class="{ 'is-valid': validStreetnumber(userData.streetnumber), 'is-invalid': !validStreetnumber(userData.streetnumber) }"
                                :value="userData.streetnumber"
                                @input="userData.streetnumber = $event.target.value">
                        <div class="invalid-feedback">
                            Die Hausnummer muss mindestens 1 Zeichen lang sein.
                        </div>
                    </div>
            </div>
            <div class="row" v-if="userData.notarialCertification == '1' && validStreetnumber(userData.streetnumber)">
                <div class="col-2">
                    <label for="zipcode">Postleitzahl</label>
                    <input
                            type="text"
                            id="street"
                            class="form-control"
                            :class="{ 'is-valid': validZipcode(userData.zipcode), 'is-invalid': !validZipcode(userData.zipcode) }"
                            :value="userData.zipcode"
                            @input="userData.zipcode = $event.target.value">
                    <div class="invalid-feedback">
                        Der Postleitzahl muss aus 5 Ziffern bestehen.
                    </div>
                </div>
                <div class="col-6" v-if="validZipcode(userData.zipcode)">
                    <label for="city">Stadt</label>
                    <input
                            type="text"
                            id="street"
                            class="form-control"
                            :class="{ 'is-valid': validCity(userData.city), 'is-invalid': !validCity(userData.city) }"
                            :value="userData.city"
                            @input="userData.city = $event.target.value">
                    <div class="invalid-feedback">
                        Die Stadt muss mindestens 2 Zeichen lang sein.
                    </div>
                </div>
            </div>
            <!-- GTC accepted -->
            <hr>
            <div class="row">
                <div class="col-xs-12 col-sm-8 col-sm-offset-2 col-md-6 col-md-offset-3">
                    <label for="gtcAccepted" v-if="userData.notarialCertification == '0' || validCity(userData.city)">
                        <input
                                type="checkbox"
                                id="gtcAccepted"
                                :class="{ 'is-valid': validGtc(userData.gtcAccepted), 'is-invalid': !validGtc(userData.gtcAccepted) }"
                                :value="userData.gtcAccepted"
                                v-model="userData.gtcAccepted"> Hiermit akzeptiere ich die allgemeinen Geschäftsbedingungen.
                        <div class="invalid-feedback">
                            Die allgemeinen Geschäftsbedingungen müssen akzeptiert werden.
                        </div>
                    </label>
                </div>
            </div>
            <!-- Register Button -->
            <div class="row">
                <div class="col-xs-12 col-sm-8 col-sm-offset-2 col-md-6 col-md-offset-3">
                    <button
                            :class="{'btn-light': userData.gtcAccepted == false, 'btn-primary': userData.gtcAccepted != false }"
                            class="btn btn-primary"
                            @click.prevent="submitted">
                            Registrieren
                    </button>
                </div>
            </div>
        </form>
    </div>
</template>

<script>    
    import Navigation from './shared/Navigation.vue';

    export default {
        data() {
            return {
                userData: {
                    firstname: '',
                    lastname: '',
                    streetname: '',
                    streetnumber: '',
                    city: '',
                    zipcode: '',
                    country: 'Deutschland',
                    mail: '',
                    active: false,
                    notarialCertification: '',
                    gtcAccepted: false
                },
                formCompleted: false,
                cryptoMailAddress: '',
                secretKey: '',
                info:''
            }
        },
        methods: {
            validFirstname: function (firstname) {
                return firstname.length >= 2;
            },
            validLastname: function (lastname) {
                return lastname.length >= 2;
            },
            validMail: function (mail) {
                var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return re.test(mail);
            },
            validPassword: function (password) {
                return password.length >= 8;
            },
            validGtc: function (gtcAccepted) {
                return gtcAccepted;
            },
            validNotarialCertification: function(notarialCertification) {
                return notarialCertification != '';
            },
            validStreetname : function(streetname) {
                return streetname.length >= 2;
            },
            validStreetnumber : function(streetnumber) {
                return streetnumber.length >= 1;
            },
            validZipcode : function(zipcode) {
                var re = /^\d{5}$/;
                return re.test(zipcode);
                return zipcode.length == 5;
            },
            validCity : function(city) {
                return city.length >= 2;
            },
            submitted() {
                //if ( !this.userData.gtcAccepted ) return;
                const axios = require('axios').default;
                axios
                    .post('https://api2.habermann.consulting/notary/api/accounts', { user: this.userData })
                    .then(response => {
                        this.cryptoMailAddress = response.data.cryptoMailAddress;
                        this.secretKey = response.data.secretKey;
                    })
                    .catch(function (error) {
                        console.log(error.response.data);
                    });
                this.formCompleted = true;
            }
        },
        mounted () {
        },
        components: {
            appNavigation: Navigation
        }
    }
</script>