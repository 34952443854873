<template>
    <div>
        <div v-if="id == 1" class="modal-body">
            <h2 class="text-uppercase">Zum Sterbefall</h2>
            <p class="item-intro text-muted">
                Niemand von uns mag daran denken, aber wir werden alle eines Tages sterben.
                Wir hoffen, dass dieser Tag sehr weit in der Zukunft liegt und unser Leben bis dahin mit vielen fröhlichen Erinnerungen gefüllt wird.
                Aber wenn der Tag kommt, sind die wenigstens Menschen vernünftig darauf vorbereitet und bestimmt denken die Angehörigen dann nicht
                als erstes an unsere Online-Accounts und all die Informationen, welche darin gespeichert wurden.
            </p>
            <p class="item-intro text-muted">
                Für einige mag es trivial klingen, aber für andere ist es sehr wichtig, dass diese Informationen dann in vertrauenswürdige
                Hände gegeben werden.
            </p>
            <img class="img-fluid d-block mx-auto" src="assets/img/portfolio/01-full.jpg" alt="" />
            <p>
                <span class="font-weight-bold">Welche Maßnahmen sollte ich ergreifen, damit meine Online-Zugänge geplant übergeben werden können?</span><br>
                Mit Ihrem digitalen Vorsorgeaccount können Sie die Übergabe zu Lebzeiten planen und einem Notar die Verantwortung dafür übertragen,
                genau die Accounts, die sie weitergeben möchten, an die zugehörigen Personen zu übergeben.
            </p>
            <p>
                <span class="font-weight-bold">Können die Accounts dadurch nicht schon zu Lebzeiten von anderen Personen genutzt werden?</span><br>
                Nein. Erst nachdem der Notar die Unterlagen an ihre Vertrauenspersonen übergeben hat, können diese eine Aktivierung und Wiederherstellung vornehmen.
            </p>
        </div>

        <!-- Modal 2-->
        <div v-else-if="id == 2" class="modal-body">
            <!-- Project Details Go Here-->
            <h2 class="text-uppercase">Sicherheit</h2>
            <p class="item-intro text-muted">In diesem Bereich erfahren Sie, wieso das System sicher ist und warum Sie keine Bedenken haben müssen.</p>
            <img class="img-fluid d-block mx-auto" src="assets/img/portfolio/02-full.jpg" alt="" />
            <p>
                <span class="font-weight-bold">Wieso ist das Verfahren sicher?</span><br>
                Die digitale Vorsorge erstellt eine kryptische E-Mail-Adresse für Sie, die Sie in Ihren zu schützenden Accounts als zusätzliche E-Mail-Adresse hinterlegen.<br>
                <br>
                Da die E-Mail-Adresse ein kryptisches Format, wie z.B. Sa7dVQ8mZKKxxau9@domain.de, hat, ist es nahezu unmöglich, diese zu erraten.
                Selbst wenn man diese jedoch erraten würde, könnte man mit ihr nichts anfangen, da die E-Mail-Adresse nicht aktiv ist, bis diese über unseren Online-Dienst aktiviert wurde.
            </p>
            <p>
                <span class="font-weight-bold">Ich habe schon mehrfach von Datenleaks gelesen, bei denen Firmenserver gehackt wurden. Wie schützen Sie meine Daten vor einem solchen Angriff auf Ihre Server?</span><br>
                <br>
                Selbstverständlich schützen wir unsere Server mit allen Mitteln gegen Hackerangriffe. Sollte es jedoch dennoch zu einem
                Datenleck kommen, sind Ihre Daten weiterhin geschützt, da wir keine Klartexte auf unseren Servern speichern.<br>
                <br>
                Dies ist durch sogenannte kryptografische Einweg-Hashfunktionen möglich. Dabei werden Ihre Domaindaten auf unseren Servern
                verschlüsselt gespeichert und können nicht wieder entschlüsselt werden. Wird Ihre kryptische E-Mail-Adresse nun aktiviert,
                so werden die von Ihren Vertrauten eingegebenen Daten ebenfalls einweg-verschlüsselt und die Hashwerte werden miteinander
                verglichen. Stimmen diese überein, so wird Ihre kryptische Vorsorge-E-Mail-Adresse aktiviert.<br>
                <br>
                Bei einem Hackerangriff würde man lediglich die kryptografierten Hashwerte vorfinden, könnte diese jedoch nicht entschlüssen und somit weder Ihnen zuordnen noch verwenden.
            </p>
            <p>
                <span class="font-weight-bold">Was passiert, falls ihr Unternehmen bis zu meinem Tod Pleite gegangen ist?</span><br>
                Wir halten diesen Fall selbstverständlich für ausgeschlossen, möchten Ihnen hierfür jedoch ebenfalls eine Sicherung bieten.
                Damit niemand Ihrer kryptischen Vorsorge-E-Mail-Adresse habhaft werden kann, können Sie für diesen Fall von uns für Sie eine eigene Domain registrieren lassen.
                Sollte unser Unternehmen pleite gehen, wäre Ihre E-Mail-Adresse weiterhin durch Sie und Ihre Vertrauten nach Ihrem Tod abrufbar, da Domains
                ebenso wie andere Wirtschaftsgüter vererbbar sind.<br>
                <br>
                Wir empfehlen hier eine notarielle Regelung, damit die Funktionsfähigkeit zügig sichergestellt werden kann.
            </p>
        </div>

        <!-- Modal 3-->
        <div v-else-if="id == 3" class="modal-body">
            <!-- Project Details Go Here-->
            <h2 class="text-uppercase">Accounts</h2>
            <p class="item-intro text-muted">In diesem Abschnitt erfahren Sie, an welche Accounts Sie denken sollten und wie Sie diese an Ihre Vertrauten übergeben können.</p>
            <img class="img-fluid d-block mx-auto" src="assets/img/portfolio/03-full.jpg" alt="" />
            <p>
                <span class="font-weight-bold">Welche Accounts sollte ich schützen?</span><br>
                Grundsätzlich sollten Sie alle Accounts übergeben, in denen sich Daten befinden, die nach Ihrem Tod für Ihre Vertrauten
                wichtig sind und die Handlungsfähigkeit aufrechterhalten.<br>
                <br>
                Ihre wichtigsten Accounts finden Sie zum Beispiel in Ihrem Passwort-Manager oder in Ihrer Browser-Historie.<br>
                <br>
                Teilen Sie nun die Accounts in mehrere Gruppen ein. So könnten Sie private Accounts z.B. für Ihren Partner zur Weiterverwendung vorbereiten
                und geschäftliche Accounts für Ihre Mit-Gesellschafter, Mitarbeiter oder Geschäftspartner.<br<
                <br>
                Für jeden gefundenen Bereich sollten Sie nun eine eigene kryptische Vorsorge-E-Mail-Adresse anlegen. Im Verlauf der Registrierung können
                Sie dann den oder die Vertrauten hinterlegen, die im Todesfall informiert werden sollen.<br>
                <br>
                Wichtige Accounts sind zum Beispiel:<br>
                <ul class="list-inline">
                    <li>Google, Googlemail</li>
                    <li>Apple ID</li>
                    <li>GMX, Freenet, DE-Mail</li>
                    <li>Online-Banking</li>
                    <li></li>
                </ul>
            </p>
        </div>
        <router-link tag="button" class="btn btn-primary" data-dismiss="modal" type="button" to="/#faq"><i class="fas fa-times mr-1"></i>Schließen</router-link>
    </div>
</template>

<script>
export default {
    props: ['id']
}
</script>