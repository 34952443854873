<template>
    <div style="margin-top:100px;" class="container">
        <app-navigation :show-menu="false" :force-shrink="true"></app-navigation>
        <h1>Zuordnung</h1>
        <p>Auf dieser Seite können Sie Ihre Accounts speichern, bei denen Sie die Vorsorge-E-Mailadresse hinterlegt haben.
            Weisen Sie die Accounts Ihren Vertrauten zu, um festzulegen, wer im Notfall die Vorsorgeinformationen zu einzelnen Accounts erhält.</p>
        <form>
            <h2>Vertrauter</h2>
            <p>Geben Sie bitte Ihren Vertrauten an, die im Notfall informiert werden sollen!</p>
            <div class="row">
                <!-- Vorname -->
                <div class="col-xs-12 col-md-3">
                    <label for="firstname">Vorname</label>
                </div>
                <div class="col-xs-12 col-md-4">
                    <label for="lastname">Nachname</label>
                </div>
                <div class="col-xs-12 col-md-3">
                    <label for="mail">E-Mail-Adresse</label>
                </div>
            </div>
            <div class="row mt-2" v-for="confidant in confidants" :key="confidant.id_confidant">
                <!-- Vorname -->
                <div class="col-xs-12 col-md-3">
                    <input
                            type="text"
                            id="firstname"
                            class="form-control"
                            :class="{ 'is-valid': isValidStringLength(confidant.firstname, 2), 'is-invalid': !isValidStringLength(confidant.firstname, 2) }"
                            :value="confidant.firstname"
                            @input="confidant.firstname = $event.target.value">
                    <div class="invalid-feedback">
                        Der Vorname muss mindestens 2 Zeichen lang sein.
                    </div>
                </div>
                <!-- Nachname -->
                <div class="col-xs-12 col-md-4">
                    <input
                            type="text"
                            id="lastname"
                            class="form-control"
                            :class="{ 'is-valid': isValidStringLength(confidant.lastname, 2), 'is-invalid': !isValidStringLength(confidant.lastname, 2) }"
                            :value="confidant.lastname"
                            @input="confidant.lastname = $event.target.value">
                    <div class="invalid-feedback">
                        Die Nachname muss mindestens 2 Zeichen lang sein.
                    </div>
                </div>
                <!-- E-Mail -->
                <div class="col-xs-12 col-md-3">
                    <input
                            type="text"
                            id="mail"
                            class="form-control"
                            :class="{ 'is-valid': isValidMail(confidant.mail), 'is-invalid': !isValidMail(confidant.mail) }"
                            :value="confidant.mail"
                            @input="confidant.mail = $event.target.value">
                    <div class="invalid-feedback">
                        Die E-Mail-Adresse muss mindestens 2 Zeichen lang sein.
                    </div>
                </div>
                <!-- Aktualisieren -->
                <div class="col-xs-12 col-md-2">
                    <button
                            class="btn btn-primary"
                            :class="{'btn-light': !isValidConfidantUpdate(confidant), 'btn-primary': isValidConfidantUpdate(confidant) }"
                            @click.prevent="updateConfidant(confidant)">
                            Aktualisieren
                    </button>
                </div>
            </div>
            <div class="row mt-2" v-if="!newConfidantRow">
                <div class="col-xs-12 col-md-12">
                    <button
                            class="btn btn-primary"
                            @click.prevent="newConfidantRow = true">
                            Neuer Vertrauter
                    </button>
                </div>
            </div>
            <div class="row mt-2" v-if="newConfidantRow">
                <!-- Account Name -->
                <div class="col-xs-12 col-md-3">
                    <input
                            type="text"
                            id="firstname"
                            class="form-control"
                            :class="{ 'is-valid': isValidStringLength(newConfidant.firstname, 2), 'is-invalid': !isValidStringLength(newConfidant.firstname, 2) }"
                            :value="newConfidant.firstname"
                            @input="newConfidant.firstname = $event.target.value">
                    <div class="invalid-feedback">
                        Der Accountname muss mindestens 2 Zeichen lang sein.
                    </div>
                </div>
                <!-- Account Description -->
                <div class="col-xs-12 col-md-4">
                    <input
                            type="text"
                            id="lastname"
                            class="form-control"
                            :class="{ 'is-valid': isValidStringLength(newConfidant.lastname, 2), 'is-invalid': !isValidStringLength(newConfidant.lastname, 2) }"
                            :value="newConfidant.lastname"
                            @input="newConfidant.lastname = $event.target.value">
                    <div class="invalid-feedback">
                        Die Accountbeschreibung muss mindestens 2 Zeichen lang sein.
                    </div>
                </div>
                <!-- E-Mail -->
                <div class="col-xs-12 col-md-3">
                    <input
                            type="text"
                            id="mail"
                            class="form-control"
                            :class="{ 'is-valid': isValidMail(newConfidant.mail), 'is-invalid': !isValidMail(newConfidant.mail) }"
                            :value="newConfidant.mail"
                            @input="newConfidant.mail = $event.target.value">
                    <div class="invalid-feedback">
                        Die E-Mail-Adresse muss mindestens 2 Zeichen lang sein.
                    </div>
                </div>
                <div class="col-xs-12 col-md-2">
                    <button
                            :class="{'btn-light': !validNewConfidant(), 'btn-primary': validNewConfidant() }"
                            class="btn btn-primary"
                            @click.prevent="insertConfidant()">
                            Hinzufügen
                    </button>
                </div>
            </div>            <br>
            <br>
            <h2>Accounts</h2>
            <p>Weisen Sie Ihre Vertrauten den Account zu, die Sie mit Vorsorge-E-Mailadressen schützen möchten!</p>
            <div class="row">
                <!-- Vorname -->
                <div class="col-xs-12 col-md-3">
                    <label for="firstname">Accountname</label>
                </div>
                <div class="col-xs-12 col-md-4">
                    <label for="lastname">Accountbeschreibung</label>
                </div>
                <div class="col-xs-12 col-md-3">
                    <label for="mail">Vertrauter</label>
                </div>
            </div>
            <div class="row mt-2" v-for="assignment in assignments" :key="assignment.id_assignment">
                <!-- Account Name -->
                <div class="col-xs-12 col-md-3">
                    <input
                            type="text"
                            id="account_name"
                            class="form-control"
                            :class="{ 'is-valid': isValidStringLength(assignment.account_name, 2), 'is-invalid': !isValidStringLength(assignment.account_name, 2) }"
                            :value="assignment.account_name"
                            @input="assignment.account_name = $event.target.value">
                    <div class="invalid-feedback">
                        Der Accountname muss mindestens 2 Zeichen lang sein.
                    </div>
                </div>
                <!-- Account Description -->
                <div class="col-xs-12 col-md-4">
                    <input
                            type="text"
                            id="account_description"
                            class="form-control"
                            :class="{ 'is-valid': isValidStringLength(assignment.account_description, 2), 'is-invalid': !isValidStringLength(assignment.account_description, 2) }"
                            :value="assignment.account_description"
                            @input="assignment.account_description = $event.target.value">
                    <div class="invalid-feedback">
                        Die Accountbeschreibung muss mindestens 2 Zeichen lang sein.
                    </div>
                </div>
                <!-- Confidants -->
                <div class="col-xs-12 col-md-3">
                    <select
                            disabled
                            id="confidant_id"
                            class="form-control"
                            v-model="assignment.confidant_id">
                        <option value="">Bitte auswählen ...</option>
                        <option v-for="confidant in confidants" :key="confidant.id_confidant" :value="confidant.id_confidant">{{ confidant.firstname }} {{ confidant.lastname }}</option>
                    </select>
                </div>
                <div class="col-xs-12 col-md-2">
                    <button
                            :class="{'btn-light': !isValidAssignmentUpdate(assignment), 'btn-primary': isValidAssignmentUpdate(assignment) }"
                            class="btn btn-primary"
                            @click.prevent="updateAssignment(assignment)">
                            Aktualisieren
                    </button>
                </div>
            </div>
            <div class="row mt-2" v-if="!newAssignmentRow">
                <div class="col-xs-12 col-md-12">
                    <button
                            class="btn btn-primary"
                            @click.prevent="newAssignmentRow = true">
                            Neuer Account
                    </button>
                </div>
            </div>
            <div class="row mt-2" v-if="newAssignmentRow">
                <!-- Vorname -->
                <div class="col-xs-12 col-md-3">
                    <input
                            type="text"
                            id="account_name"
                            class="form-control"
                            :class="{ 'is-valid': isValidStringLength(newAssignment.account_name, 2), 'is-invalid': !isValidStringLength(newAssignment.account_name, 2) }"
                            :value="newAssignment.account_name"
                            @input="newAssignment.account_name = $event.target.value">
                    <div class="invalid-feedback">
                        Der Accountname muss mindestens 2 Zeichen lang sein.
                    </div>
                </div>
                <!-- Nachname -->
                <div class="col-xs-12 col-md-4">
                    <input
                            type="text"
                            id="account_description"
                            class="form-control"
                            :class="{ 'is-valid': isValidStringLength(newAssignment.account_description, 2), 'is-invalid': !isValidStringLength(newAssignment.account_description, 2) }"
                            :value="newAssignment.account_description"
                            @input="newAssignment.account_description = $event.target.value">
                    <div class="invalid-feedback">
                        Die Accountbeschreibung muss mindestens 2 Zeichen lang sein.
                    </div>
                </div>
                <!-- E-Mail -->
                <div class="col-xs-12 col-md-3">
                    <select
                            id="confidant_id"
                            class="form-control"
                            :class="{ 'is-valid': validConfidant(newAssignment.confidant_id), 'is-invalid': !validConfidant(newAssignment.confidant_id) }"
                            v-model="newAssignment.confidant_id">
                        <option value="">Bitte auswählen ...</option>
                        <option v-for="confidant in confidants" :key="confidant.id_confidant" :value="confidant.id_confidant">{{ confidant.firstname }} {{ confidant.lastname }}</option>
                    </select>
                </div>
                <div class="col-xs-12 col-md-2">
                    <button
                            :class="{'btn-light': !validNewAssignment(), 'btn-primary': validNewAssignment() }"
                            class="btn btn-primary"
                            @click.prevent="insertAssignment()">
                            Hinzufügen
                    </button>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
    import Navigation from './shared/Navigation.vue';
    import Masthead from './home/Masthead.vue';
    import Functionality from './home/Functionality.vue';
    import Faq from './home/Faq.vue';
    import Contact from './home/Contact.vue';

    export default {
        data() {
            return {
                secretKey: this.$route.params.id,
                serverConfidants: [],
                confidants: [],
                newConfidant: {
                    firstname: '',
                    lastname: '',
                    mail: ''
                },
                serverAssignments: [],
                assignments: [],
                newAssignment: {
                    account_name: '',
                    account_description: '',
                    confidant_id: ''
                },
                newAssignmentRow: false,
                newConfidantRow: false
            }
        },
        components: {
            appNavigation: Navigation,
            appMasthead: Masthead,
            appFunctionality: Functionality,
            appFaq: Faq,
            appContact: Contact,
        },
        methods: {
            isValidStringLength: function (value, length) {
                return value.length >= length;
            },
            isValidMail: function (mail) {
                var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return re.test(mail);
            },
            validConfidant: function (confidant) {
                return confidant != '';
            },
            isValidConfidantUpdate: function (confidant) {
                return this.isValidStringLength(confidant.firstname, 2)
                        && this.isValidStringLength(confidant.lastname, 2)
                        && this.validConfidant(confidant.mail)
                        && (
                            this.serverConfidants[confidant.id_confidant].firstname != this.confidants[confidant.id_confidant].firstname
                            || this.serverConfidants[confidant.id_confidant].lastname != this.confidants[confidant.id_confidant].lastname
                            || this.serverConfidants[confidant.id_confidant].mail != this.confidants[confidant.id_confidant].mail
                        );
            },
            isValidAssignmentUpdate: function (assignment) {
                return this.isValidStringLength(assignment.account_name, 2)
                        && this.isValidStringLength(assignment.account_description, 2)
                        && this.validConfidant(assignment.confidant_id)
                        && (
                            this.serverAssignments[assignment.id_assignment].account_name != this.assignments[assignment.id_assignment].account_name
                            || this.serverAssignments[assignment.id_assignment].account_name != this.assignments[assignment.id_assignment].account_name
                            || this.serverAssignments[assignment.id_assignment].confidant_id != this.assignments[assignment.id_assignment].confidant_id
                        );
            },
            validNewAssignment: function () {
                return this.isValidStringLength(this.newAssignment.account_name, 2)
                        && this.isValidStringLength(this.newAssignment.account_description, 2)
                        && this.validConfidant(this.newAssignment.confidant_id);
            },
            validNewConfidant: function () {
                return this.isValidStringLength(this.newConfidant.firstname, 2)
                        && this.isValidStringLength(this.newConfidant.lastname, 2)
                        && this.isValidMail(this.newConfidant.mail);
            },
            updateAssignment(assignment) {
                if ( !this.isValidAssignmentUpdate(assignment) ) return;
                const axios = require('axios').default;
                axios
                    .put('https://api2.habermann.consulting/notary/api/assignments',  { 'secretKey': this.secretKey, 'assignment': assignment })
                    .then(r => this.getAssignments())
                    .catch(function (error) {
                        console.log(error.response.data);
                    });
            },
            updateConfidant(confidant) {
                const axios = require('axios').default;
                axios
                    .put('https://api2.habermann.consulting/notary/api/confidants',  { 'secretKey': this.secretKey, 'confidant': confidant })
                    .then(r => this.getConfidants())
                    .catch(function (error) {
                        console.log(error.response.data);
                    });
            },
            insertAssignment() {
                if ( !this.validNewAssignment(this.newAssignment) ) return;
                const axios = require('axios').default;
                axios
                    .post('https://api2.habermann.consulting/notary/api/assignments',  { 'secretKey': this.secretKey, 'assignment': this.newAssignment })
                    .then(r => this.getAssignments())
                    .then(r => this.newAssignment.account_name = '')
                    .then(r => this.newAssignment.account_description = '')
                    .then(r => this.newAssignment.confidant_id = '')
                    .then(r => this.newAssignmentRow = false)
                    .catch(function (error) {
                        console.log(error.response.data);
                    });
            },
            insertConfidant() {
                if ( !this.validNewConfidant(this.newConfidant) ) return;
                const axios = require('axios').default;
                axios
                    .post('https://api2.habermann.consulting/notary/api/confidants',  { 'secretKey': this.secretKey, 'confidant': this.newConfidant })
                    .then(r => this.getConfidants())
                    .then(r => this.newConfidant.firstname = '')
                    .then(r => this.newConfidant.lastname = '')
                    .then(r => this.newConfidant.row = '')
                    .then(r => this.newConfidantRow = false)
                    .catch(function (error) {
                        console.log(error.response.data);
                    });
            },
            getAssignments() {
                const axios = require('axios').default;
                axios
                    .get('https://api2.habermann.consulting/notary/api/assignments/' + this.secretKey, { user: this.userData })
                    .then(
                        response => (this.assignments = response.data, this.serverAssignments = JSON.parse(JSON.stringify(response.data)) )
                    )
                    .catch(function (error) {
                        console.log(error.response.data);
                    });                
            },
            getConfidants() {
                const axios = require('axios').default;
                axios
                    .get('https://api2.habermann.consulting/notary/api/confidants/' + this.secretKey, { user: this.userData })
                    .then(
                        response => (this.confidants = response.data, this.serverConfidants = JSON.parse(JSON.stringify(response.data)) )
                    )
                    .catch(function (error) {
                        console.log(error.response.data);
                    });
            }
        },
        mounted() {
            const axios = require('axios').default;
            this.getAssignments();
            this.getConfidants();
        }
    }
</script>