<template>
    <div style="margin-top:100px;" class="container">
        <app-navigation :show-menu="false" :force-shrink="true"></app-navigation>
        <app-faq-details :id="id"></app-faq-details>
    </div>
</template>

<script>
    import Navigation from './shared/Navigation.vue';
    import FaqDetails from './faq/FaqDetails.vue';

    export default {
        data() {
            return {
                id: this.$route.params.id
            }
        },
        components: {
            appNavigation: Navigation,
            appFaqDetails: FaqDetails
        }
    }
</script>