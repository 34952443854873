import Home from './components/Home.vue';
import Register from './components/Register.vue';
import Assignment from './components/Assignment.vue';
import Activation from './components/Activation.vue';
import Faq from './components/Faq.vue';

export const routes = [
    { path: '/', component: Home },
    { path: '/register', component: Register },
    { path: '/activation', component: Activation },
    { path: '/assignment/:id', component: Assignment },
    { path: '/faq/:id', component: Faq }
];