<template>
    <div style="margin-top:100px;" class="container">
        <app-navigation :show-menu="false" :force-shrink="true"></app-navigation>

        <div v-if="formCompleted">
            <h1>Aktivierung erfolgreich</h1>
            <p>Vielen Dank für Ihre Aktivierung der Vorsorge-E-Mail-Adresse {{ cryptoMailAddress }}, die Sie hiermit erfolgreich abgeschlossen haben.</p>
            <p>Was sind die nächsten Schritte?</p>
            <ol>
                <li>Ihre kryptische Vorsorge-E-Mail-Adresse leitet nun E-Mails an Ihr hinterlegtes Postfach weiter.</span></li>
                <li>Benutzen Sie die Passwort-Vergessen-Funktion der Accounts, bei denen die Vorsorge-E-Mail-Adresse hinterlegt wurde!</li>
                <li>Die Freischaltungs-E-Mails werden an Ihr hinterlegtes Postfach weitergeleitet.</li>
            </ol>
        </div>

        {{ info }}

        <form v-if="formCompleted == false">
        <h1>Aktivierung</h1>
            <div class="row">
                <div class="col-xs-12 col-sm-8 col-sm-offset-2 col-md-6 col-md-offset-3">
                    <hr>
                    <div class="form-group">
                        <label for="activationCode">Aktivierungscode</label>
                        <input
                                type="text"
                                id="activationCode"
                                class="form-control"
                                :class="{ 'is-valid': validActivationCode(userData.activationCode), 'is-invalid': !validActivationCode(userData.activationCode) }"
                                :value="userData.activationCode"
                                @input="userData.activationCode = $event.target.value">
                        <div class="invalid-feedback">
                            Der Aktivierungscode muss mindestens 6 Zeichen lang sein.
                        </div>
                    </div>
                    <div v-if="validActivationCode(userData.activationCode)" class="form-group">
                        <label for="redirectMail">Weiterleitungs-E-Mail-Adresse</label>
                        <input
                                type="text"
                                id="redirectMail"
                                class="form-control"
                                :class="{ 'is-valid': validEmail(userData.redirectMail), 'is-invalid': !validEmail(userData.redirectMail) }"
                                :value="userData.redirectMail"
                                @input="userData.redirectMail = $event.target.value">
                        <div class="invalid-feedback">
                            Die E-Mail-Adresse muss das Format aaa@bbb.cc haben.
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-xs-12 col-sm-8 col-sm-offset-2 col-md-6 col-md-offset-3">
                    <button
                            :class="{'btn-light': !validEmail(userData.redirectMail), 'btn-primary': validEmail(userData.redirectMail) }"
                            class="btn btn-primary"
                            @click.prevent="submitted">
                            Aktivieren
                    </button>
                </div>
            </div>
        </form>
    </div>
</template>

<script>    
    import Navigation from './shared/Navigation.vue';

    export default {
        data() {
            return {
                userData: {
                    activationCode: '',
                    redirectMail: '',
                },
                formCompleted: false,
                cryptoMailAddress: '',
                info:''
            }
        },
        methods: {
            validActivationCode: function (firstname) {
                return firstname.length >= 6;
            },
            validEmail: function (email) {
                var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return re.test(email);
            },
            submitted() {
                //if ( !this.userData.gtcAccepted ) return;
                const axios = require('axios').default;
                axios
                    .patch('https://api2.habermann.consulting/notary/api/accounts/' + this.userData.activationCode, { user: this.userData })
                    .then(response => (this.cryptoMailAddress = response.data.cryptoMailAddress))
                    .catch(function (error) {
                        console.log(error.response.data);
                    });
                this.formCompleted = true;
            }
        },
        mounted () {
        },
        components: {
            appNavigation: Navigation
        }
    }
</script>