<template>
    <div>
        <!-- Navigation-->
        <nav :class="{ 'navbar-shrink': navbarShrink }" class="navbar navbar-expand-lg navbar-dark fixed-top" id="mainNav">
            <div class="container">
                <router-link tag="a" class="navbar-brand js-scroll-trigger" to="/#page-top">
                    <!-- <img src="assets/img/navbar-logo.svg" alt="" /> -->
                    Digitale Vorsorge
                </router-link>
                <button v-if="showMenu" class="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">Menu<i class="fas fa-bars ml-1"></i></button>
                <div v-if="showMenu" class="collapse navbar-collapse" id="navbarResponsive">
                    <ul class="navbar-nav text-uppercase ml-auto">
                        <li class="nav-item">
                            <router-link tag="a" style="scroll-behavior: smooth;" active-class="active" class="nav-link js-scroll-trigger" to="#functionality">Funktionsweise</router-link>
                        </li>
                        <li tag="li" class="nav-item" active-class="active">
                            <router-link tag="a" active-class="active" class="nav-link js-scroll-trigger" to="#faq">Häufige Fragen</router-link>
                        </li>
                        <li tag="li" class="nav-item" active-class="active">
                            <router-link tag="a" active-class="active" class="nav-link js-scroll-trigger" to="#contact">Terminvereinbarung</router-link>
                        </li>
                        <li tag="li" class="nav-item" active-class="active">
                            <router-link tag="a" active-class="active" class="nav-link js-scroll-trigger" to="/activation">Aktivierung</router-link>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    </div>
</template>

<script>
export default {
    props: {
        showMenu: { default: true },
        forceShrink: { default: false }
    },
    data() {
        return {
            navbarShrink: false
        }
    },
    created () {
        window.addEventListener('scroll', this.navbar);
        if (this.forceShrink) this.navbarShrink = true;
    },
    destroyed () {
        window.removeEventListener('scroll', this.navbar);
    },
    methods: {
        navbar(event) {
            if (this.forceShrink || document.body.getBoundingClientRect().top <= -100 ) {
                this.navbarShrink = true;
            } else {
                this.navbarShrink = false;
            }
        },
        scrollMeTo(refName) {
            var element = this.$refs[refName];
            var top = element.offsetTop;
            window.scrollTo(0, top);
        }
    }
}
</script>