<template>
    <div>
        <app-navigation :show-menu="true"></app-navigation>
        <app-masthead></app-masthead>
        <app-functionality></app-functionality>
        <app-faq></app-faq>
        <app-contact></app-contact>
    </div>
</template>

<script>
    import Navigation from './shared/Navigation.vue';
    import Masthead from './home/Masthead.vue';
    import Functionality from './home/Functionality.vue';
    import Faq from './home/Faq.vue';
    import Contact from './home/Contact.vue';

    export default {
        components: {
            appNavigation: Navigation,
            appMasthead: Masthead,
            appFunctionality: Functionality,
            appFaq: Faq,
            appContact: Contact,
        }        
    }
</script>